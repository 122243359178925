import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from "react-intl";
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {ServerError} from "@apollo/client/link/utils"
import { setContext } from '@apollo/client/link/context';
import { theme } from './theme';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import './theme/global.css';
import {messages} from "./settings/site-translation/messages";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('auth_token');

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    }
  }
});

// если в ответе от сервера возвращается ошибка
const resetToken = onError(({ response, networkError }) => {
  if (networkError && (networkError as ServerError).statusCode === 401) {
    localStorage.removeItem('auth_token');
    document.location.href = '/';
  }
});

const client = new ApolloClient({
  link: resetToken.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

function App() {
  const engine = new Styletron();

  return (
    <ApolloProvider client={client as any}>
      <StyletronProvider value={engine}>
        <IntlProvider messages={messages['ru']} locale="ru">
          <BaseProvider theme={theme}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </BaseProvider>
        </IntlProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
