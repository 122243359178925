import {PRODUCT_GROUP_TYPE_SERVICE} from "../settings/constants";

export default (items, orderId) => {
    let errorItems = [];

    items.map(item => {
        if (item.productGroup.type === PRODUCT_GROUP_TYPE_SERVICE) {
            return;
        }

        let quantity = item.quantity;
        const availableProductCount = item.productGroup.available_products_count;

        item.productGroup.reserved_products_for_interval.map(productReserve => {
            if (productReserve.order_id == orderId) {
                quantity -= productReserve.quantity;
            }
        });

        if (quantity > availableProductCount) {
            errorItems.push(item);
        }
    });

    return errorItems;
}
