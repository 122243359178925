import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  LOGIN,
  PRODUCTS,
  CREATE_PRODUCT,
  CATEGORY,
  DASHBOARD,
  ORDERS,
  CREATE_ORDER,
  SETTINGS,
  CUSTOMERS,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  COUPONS,
  STAFF_MEMBERS,
  SITE_SETTINGS,
  UPDATE_PRODUCT, CREATE_COUPON, UPDATE_COUPON,
  UPDATE_ORDER
} from 'settings/constants';
import AuthProvider, { AuthContext } from 'context/auth';
import {OrderProvider} from "./context/order/use-order";
import { InLineLoader } from 'components/InlineLoader/InlineLoader';
const Products = lazy(() => import('containers/ProductGroups/ProductGroups'));
const CreateProductGroup = lazy(() => import('containers/ProductGroupForm/ProductGroupForm'));
const UpdateProductGroup = lazy(() => import('containers/ProductGroupForm/UpdateProductGroup'));
const AdminLayout = lazy(() => import('containers/Layout/Layout'));
const Dashboard = lazy(() => import('containers/Dashboard/Dashboard'));
const Category = lazy(() => import('containers/Category/Category'));
const Orders = lazy(() => import('containers/Orders/Orders'));
const CreateOrder = lazy(() => import('containers/OrderForm/CreateOrderForm'));
const UpdateOrder = lazy(() => import('containers/OrderForm/UpdateOrder/UpdateOrderForm'));
const Settings = lazy(() => import('containers/Settings/Settings'));
const SiteSettingForm = lazy(() => import('containers/SiteSettingForm/SiteSettingForm'));
const StaffMembers = lazy(() => import('containers/StaffMembers/StaffMembers'));
const Customers = lazy(() => import('containers/Customers/Customers'));
const CreateCustomer = lazy(() => import('containers/CustomerForm/CreateCustomerForm'));
const UpdateCustomer = lazy(() => import('containers/CustomerForm/UpdateCustomer'));
const Coupons = lazy(() => import('containers/Coupons/Coupons'));
const CreateCoupon = lazy(() => import('containers/CouponForm/CreateCoupon'));
const UpdateCoupon = lazy(() => import('containers/CouponForm/UpdateCoupon'));
const Login = lazy(() => import('containers/Login/Login'));
const NotFound = lazy(() => import('containers/NotFound/NotFound'));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <OrderProvider>
        <Suspense fallback={<InLineLoader />}>
          <Switch>
            <PrivateRoute exact={true} path={DASHBOARD}>
              <AdminLayout path={DASHBOARD}>
                <Suspense fallback={<InLineLoader />}>
                  <Dashboard />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={CREATE_PRODUCT}>
              <AdminLayout path={CREATE_PRODUCT}>
                <Suspense fallback={<InLineLoader />}>
                  <CreateProductGroup />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={UPDATE_PRODUCT}>
              <AdminLayout path={UPDATE_PRODUCT}>
                <Suspense fallback={<InLineLoader />}>
                  <UpdateProductGroup />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={PRODUCTS}>
              <AdminLayout path={PRODUCTS}>
                <Suspense fallback={<InLineLoader />}>
                  <Products />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={CATEGORY}>
              <AdminLayout path={CATEGORY}>
                <Suspense fallback={<InLineLoader />}>
                  <Category />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={CREATE_ORDER}>
              <AdminLayout path={CREATE_ORDER}>
                <Suspense fallback={<InLineLoader />}>
                  <CreateOrder/>
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={UPDATE_ORDER}>
              <AdminLayout path={UPDATE_ORDER} withCustomTopBar={true}>
                <Suspense fallback={<InLineLoader />}>
                  <UpdateOrder/>
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={ORDERS}>
              <AdminLayout path={ORDERS}>
                <Suspense fallback={<InLineLoader />}>
                  <Orders />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={CREATE_CUSTOMER}>
              <AdminLayout path={CREATE_CUSTOMER}>
                <Suspense fallback={<InLineLoader />}>
                  <CreateCustomer/>
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={UPDATE_CUSTOMER}>
              <AdminLayout path={UPDATE_CUSTOMER}>
                <Suspense fallback={<InLineLoader />}>
                  <UpdateCustomer/>
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={CUSTOMERS}>
              <AdminLayout path={CUSTOMERS}>
                <Suspense fallback={<InLineLoader />}>
                  <Customers />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={CREATE_COUPON}>
              <AdminLayout path={CREATE_COUPON}>
                <Suspense fallback={<InLineLoader />}>
                  <CreateCoupon />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={UPDATE_COUPON}>
              <AdminLayout path={UPDATE_COUPON}>
                <Suspense fallback={<InLineLoader />}>
                  <UpdateCoupon />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={COUPONS}>
              <AdminLayout path={COUPONS}>
                <Suspense fallback={<InLineLoader />}>
                  <Coupons />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={SETTINGS}>
              <AdminLayout path={SETTINGS}>
                <Suspense fallback={<InLineLoader />}>
                  <Settings />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={STAFF_MEMBERS}>
              <AdminLayout path={STAFF_MEMBERS}>
                <Suspense fallback={<InLineLoader />}>
                  <StaffMembers />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={SITE_SETTINGS}>
              <AdminLayout path={SITE_SETTINGS}>
                <Suspense fallback={<InLineLoader />}>
                  <SiteSettingForm />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <Route path={LOGIN}>
              <Login />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </OrderProvider>
    </AuthProvider>
  );
};

export default Routes;
