// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const PRODUCTS = '/products';
export const CREATE_PRODUCT = '/products/create';
export const UPDATE_PRODUCT = '/products/update/:id';
export const CATEGORY = '/category';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const ORDERS = '/orders/:type?';
export const CREATE_ORDER = '/orders/create';
export const UPDATE_ORDER = '/orders/update/:id'
export const CUSTOMERS = '/customers';
export const CREATE_CUSTOMER = '/customers/create';
export const UPDATE_CUSTOMER = '/customers/update/:id';
export const COUPONS = '/coupons';
export const CREATE_COUPON = '/coupons/create';
export const UPDATE_COUPON = '/coupons/update/:id';
export const SETTINGS = '/settings';
export const STAFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '₽';

export const PRODUCT_GROUP_TYPE_RENTAL = 'rental';
export const PRODUCT_GROUP_TYPE_CONSUMABLE = 'consumable';
export const PRODUCT_GROUP_TYPE_SERVICE = 'service';

export const CUSTOMER_TYPE_INDIVIDUAL = 'individual';
export const CUSTOMER_TYPE_SOLE_PROPRIETOR = 'sole_proprietor';
export const CUSTOMER_TYPE_LLC = 'llc';
export const CUSTOMER_TYPE_DEFAULT = 'default';

export const ORDER_STATUS_CONCEPT = 'concept';
export const ORDER_STATUS_STARTED = 'started';
export const ORDER_STATUS_ARCHIVED = 'archived';
export const ORDER_STATUS_CANCELLED = 'canceled';
export const ORDER_STATUS_RESERVED = 'reserved';
export const ORDER_STATUS_RETURNED = 'stopped';
export const ORDER_STATUS_MIXED = 'mixed';

export const ORDER_PAYMENT_STATUS_PAYMENT_DUE = 'payment_due';
export const ORDER_PAYMENT_STATUS_PARTIALLY_PAID = 'partially_paid';
export const ORDER_PAYMENT_STATUS_PAID = 'Paid';
export const ORDER_PAYMENT_STATUS_OVERPAID = 'overpaid';

export const CUSTOM_ORDER_ITEM_TYPE_CHARGE = 'charge';
export const CUSTOM_ORDER_ITEM_TYPE_SECTION = 'section';
