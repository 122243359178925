import React, { useReducer, useContext, createContext } from 'react';
import { reducer, cartItemsTotalPrice } from './order.reducer';
import { useStorage } from 'utils/use-storage';
import {calculateDefaultDateRange} from "../../utils/calculateDefaultRangeDate";
import validateProductCart from "../../utils/checkIsProductCartValid";

const OrderContext = createContext({} as any);
const rangeDate = calculateDefaultDateRange();

const INITIAL_STATE = {
  orderStartDate: rangeDate[0],
  orderFinishDate: rangeDate[1],
  status: null,
  customer: null,
  items: [],
  customItems: [],
  tags: [],
  notes: [],
  discountPercent: null,
  totalDamageDeposit: 0,
  coupon: null,
  isValid: null,
  errorMessage: null,
  rehydrated: false,
  actualized: false,
};

const useCartActions = (initialCart = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialCart);

  const rehydrateLocalState = (payload) => {
    dispatch({ type: 'REHYDRATE', payload });
  };
  const orderDateChangeIntervalHandler = (startDate, finishDate) => {
    dispatch({ type: 'SET_ORDER_DATE_INTERVAL_VALUE', payload: { startDate, finishDate } });
  };
  const setCustomerHandler = (customer) => {
    dispatch({ type: 'SET_CUSTOMER', payload: customer });
  };
  const addItemHandler = (item) => {
    dispatch({ type: 'ADD_ITEM', payload: { ...item } });
  };
  const removeItemHandler = (index) => {
    dispatch({ type: 'REMOVE_ITEM', payload: index });
  };
  const updateItemFieldHandler = (index, field, value) => {
    dispatch({ type: 'UPDATE_ITEM_FIELD', payload: { index: index, field: field, value: value }});
  };
  const addCustomItemHandler = (customItem) => {
    dispatch({ type: 'ADD_CUSTOM_ITEM', payload: { ...customItem } });
  }
  const updateCustomItemFieldHandler = (index, field, value) => {
    dispatch({ type: 'UPDATE_CUSTOM_ITEM_FIELD', payload: { index: index, field: field, value: value }});
  };
  const removeCustomItemHandler = (index) => {
    dispatch({ type: 'REMOVE_CUSTOM_ITEM', payload: index });
  };
  const discountPercentChangeHandler = (value) => {
    dispatch({ type: 'SET_DISCOUNT_PERCENT_VALUE', payload: value });
  };
  const couponChangeHandler = (coupon) => {
    dispatch({ type: 'SET_COUPON', payload: coupon });
  };
  const totalDamageDepositChangeHandler = (value) => {
    dispatch({ type: 'SET_TOTAL_DAMAGE_DEPOSIT', payload: value });
  };
  const clearCartHandler = () => {
    dispatch({ type: 'CLEAR_CART' });
  };
  const recalculateTotalDamageDeposit = () => {
    let totalDamageDeposit = 0;
    state.items.map(item => {
      if (item.damageDeposit !== 0) {
        totalDamageDeposit += item.damageDeposit * parseInt(item.quantity);
      }
    });

    totalDamageDepositChangeHandler(totalDamageDeposit);
  };
  const changeActualized = (actualized) => {
    dispatch({ type: 'CHANGE_ACTUALIZED', payload: actualized });
  };
  const addTagHandler = (tag) => {
    dispatch({ type: 'ADD_TAG', payload: tag });
  }
  const removeTagHandler = (index) => {
    dispatch({ type: 'REMOVE_TAG', payload: index });
  }
  const addNoteHandler = (tag) => {
    dispatch({ type: 'ADD_NOTE', payload: tag });
  }
  const removeNoteHandler = (index) => {
    dispatch({ type: 'REMOVE_NOTE', payload: index });
  }
  const resetState = () => {
    dispatch({ type: 'RESET', payload: INITIAL_STATE });
  }


  const updateAvailableProductsCount = (payload) => {
    dispatch({ type: 'UPDATE_AVAILABLE_PRODUCTS_COUNT_CART', payload });
  };
  const isInCartHandler = (id) => {
    return state.items?.some((item) => item.id === id);
  };
  const getItemHandler = (id) => {
    return state.items?.find((item) => item.id === id);
  };
  const getCartItemsPrice = () => cartItemsTotalPrice(state.items, null, state.startDate, state.finishDate).toFixed(2);
  const checkIsOrderDatesValid = () => {
    const currentDate = new Date();
    const startDateObject = new Date(state.startDate);
    const finishDateObject = new Date(state.finishDate);

    if (startDateObject.getTime() >= finishDateObject.getTime()) {
      return false;
    }

    if (startDateObject.getTime() < currentDate.getTime() || finishDateObject.getTime() < currentDate.getTime()) {
      return false;
    }

    return true;
  };
  const getCartItemsTotalPrice = () => {
    // Подсчет стоимости заказа с учетом дней
    let totalPrice = parseFloat(cartItemsTotalPrice(state.items, state.coupon, state.startDate, state.finishDate).toFixed(2));

    state.servicesList.map(service => {
      totalPrice += state.selectedServices.includes(service.id) ? parseInt(service.price) : 0;
    });

    return totalPrice;
  }

  const getDiscount = () => {
    const total = cartItemsTotalPrice(state.items, null, state.startDate, state.finishDate);
    const discount = state.coupon
      ? (total * Number(state.coupon?.discountInPercent)) / 100
      : 0;
    return discount.toFixed(2);
  };
  const getItemsCount = state.items?.reduce(
    (acc, item) => acc + item.quantity,
    0
  );
  const cartValidationHandler = (payload) => {
    dispatch({ type: 'SET_CART_VALIDATION_RESULT', payload});
  };
  const checkIsProductCartValid = () => {
    // проверка возможности бронирования заказа
    return validateProductCart(state.items, null);
  };

  return {
    state,
    getItemsCount,
    rehydrateLocalState,
    addItemHandler,
    removeItemHandler,
    updateItemFieldHandler,
    addCustomItemHandler,
    updateCustomItemFieldHandler,
    removeCustomItemHandler,
    discountPercentChangeHandler,
    couponChangeHandler,
    totalDamageDepositChangeHandler,
    recalculateTotalDamageDeposit,
    changeActualized,
    addTagHandler,
    removeTagHandler,
    addNoteHandler,
    removeNoteHandler,
    resetState,
    checkIsProductCartValid,

    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    orderDateChangeIntervalHandler,
    getCartItemsTotalPrice,
    getCartItemsPrice,
    getDiscount,
    updateAvailableProductsCount,
    cartValidationHandler,
    checkIsOrderDatesValid,
    setCustomerHandler
  };
};

export const OrderProvider = ({ children }) => {
  const {
    state,
    rehydrateLocalState,
    getItemsCount,
    addItemHandler,
    removeItemHandler,
    updateItemFieldHandler,
    addCustomItemHandler,
    updateCustomItemFieldHandler,
    removeCustomItemHandler,
    discountPercentChangeHandler,
    couponChangeHandler,
    totalDamageDepositChangeHandler,
    recalculateTotalDamageDeposit,
    changeActualized,
    addTagHandler,
    removeTagHandler,
    addNoteHandler,
    removeNoteHandler,
    resetState,
    checkIsProductCartValid,

    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    orderDateChangeIntervalHandler,
    getCartItemsTotalPrice,
    getCartItemsPrice,
    getDiscount,
    updateAvailableProductsCount,
    cartValidationHandler,
    checkIsOrderDatesValid,
    setCustomerHandler
  } = useCartActions();

  useStorage(state, rehydrateLocalState);

  return (
    <OrderContext.Provider
      value={{
        orderStartDate: state.orderStartDate,
        orderFinishDate: state.orderFinishDate,
        customer: state.customer,
        items: state.items,
        customItems: state.customItems,
        actualized: state.actualized,
        discountPercent: state.discountPercent,
        coupon: state.coupon,
        totalDamageDeposit: state.totalDamageDeposit,
        status: state.status,
        tags: state.tags,
        notes: state.notes,
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        updateItemField: updateItemFieldHandler,
        addCustomItem: addCustomItemHandler,
        updateCustomItemField: updateCustomItemFieldHandler,
        removeCustomItem: removeCustomItemHandler,
        changeDiscountPercent: discountPercentChangeHandler,
        changeCoupon: couponChangeHandler,
        changeTotalDamageDeposit: totalDamageDepositChangeHandler,
        recalculateTotalDamageDeposit: recalculateTotalDamageDeposit,
        changeActualized: changeActualized,
        addTag: addTagHandler,
        removeTag: removeTagHandler,
        addNote: addNoteHandler,
        removeNote: removeNoteHandler,
        setCustomer: setCustomerHandler,
        changeOrderDateInterval: orderDateChangeIntervalHandler,
        resetOrder: resetState,

        cartItemsCount: state.items?.length,
        itemsCount: getItemsCount,
        isCartValid: state.isValid,
        cartErrorMessage: state.errorMessage,
        clearCart: clearCartHandler,
        isInCart: isInCartHandler,
        getItem: getItemHandler,
        calculatePrice: getCartItemsTotalPrice,
        calculateSubTotalPrice: getCartItemsPrice,
        calculateDiscount: getDiscount,
        updateAvailableProductsCount: updateAvailableProductsCount,
        setCartValidationResult: cartValidationHandler,
        checkIsOrderDatesValid: checkIsOrderDatesValid,
        checkIsProductCartValid: checkIsProductCartValid
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => useContext(OrderContext);
