import moment from 'moment';

// export const cartItemsTotalPrice = (items, { discountInPercent = 0 } = {}) => {
export const cartItemsTotalPrice = (items, coupon = null, startDate, finishDate) => {
  if (items === null || items.length === 0) return 0;

  const itemCost = items.reduce((total, item) => {
    const price = item.salePrice ?? item.price;
    let diffInDays = Math.ceil(moment(finishDate).diff(moment(startDate), 'hours') / 24);
    // Если разница меньше часа
    diffInDays = diffInDays === 0 ? 1 : diffInDays;

    return total + price * item.quantity * diffInDays;
  }, 0);
  // const discountRate = 1 - discountInPercent;
  const discount = coupon
    ? (itemCost * Number(coupon.discountInPercent)) / 100
    : 0;
  // itemCost * discountRate * TAX_RATE + shipping;
  // return itemCost * discountRate;

  return itemCost - discount;
};

// добавление товара в заказ
const addItemToCart = (state, action) => {
  return [...state.items, action.payload];
};
const removeItem = (state, action) => {
  return state.items.filter((item, index) => index !== action.payload)
};
// добавление метки
const addTag = (state, action) => {
  return [...state.tags, action.payload];
};
// удаление метки
const removeTag = (state, action) => {
  return state.tags.filter((item, index) => index !== action.payload)
};
// добавление заметки
const addNote = (state, action) => {
  return [...state.notes, action.payload];
};
// удаление заметки
const removeNote = (state, action) => {
  return state.notes.filter((item, index) => index !== action.payload)
};
// обновление поля позиции заказа
const updateItemField = (state, action) => {
  return state.items.map((item, index) => {
    if (index === action.payload.index) {
      return {
        ...item,
        [action.payload.field]: action.payload.value
      }
    }

    return item;
  })
};
// добавление кастомной позиции в заказ
const addCustomItemToCart = (state, action) => {
  return [...state.customItems, action.payload];
};
// обновление кастомной позиции заказа
const updateCustomItemField = (state, action) => {
  return state.customItems.map((customItem, index) => {
    if (index === action.payload.index) {
      return {
        ...customItem,
        [action.payload.field]: action.payload.value
      }
    }

    return customItem;
  })
};
const removeCustomItem = (state, action) => {
  return state.customItems.filter((item, index) => index !== action.payload);
};

const updateCartItems = (state, action) => {
  // Удаляем из корзины неактивные товары
  let items = state.items.filter(item => {
    return action.payload.some(newItem => { return newItem.id === item.id});
  });

  // Обновляем информацию о количестве доступного оборудования
  items = items.map(item => {
    const newItem = action.payload.find(newItem => {
      return item.id == newItem.id;
    });
    item.availableProductsCount = newItem.availableProductsCount;

    return item;
  });

  return items;
};

const rehydrateState = (state, action) => {
  if (!action.payload || Object.keys(action.payload).length === 0) {
    return {
      ...state,
      ...action.payload,
      rehydrated: true,
      actualized: false
    };
  }

  return {
    ...state,
    ...action.payload,
    rehydrated: true,
    actualized: false,
    orderStartDate: action.payload.orderStartDate !== null ? new Date(action.payload.orderStartDate) : null,
    orderFinishDate: action.payload.orderFinishDate !== null ? new Date(action.payload.orderFinishDate) : null
  };
}

const changeSelectedServices = (state, action) => {
  let selectedServices = state.selectedServices;
  if (!selectedServices.includes(action.payload)) {
    selectedServices.push(action.payload);
  } else {
    selectedServices.splice(selectedServices.indexOf(action.payload), 1);
  }

  return selectedServices;
};

export const reducer = (state, action) => {
  if (!state.rehydrated && action.type !== 'REHYDRATE') {
    return state;
  }

  switch (action.type) {
    case 'RESET':
      return {
        ...action.payload,
        rehydrated: true,
        actualized: false,
      };
    case 'REHYDRATE':
      return rehydrateState(state, action);
    case 'SET_CUSTOMER':
      return { ...state, customer: action.payload };
    case 'SET_ORDER_DATE_INTERVAL_VALUE':
      return { ...state, orderStartDate: action.payload.startDate, orderFinishDate: action.payload.finishDate };
    case 'ADD_ITEM':
      return { ...state, items: addItemToCart(state, action) };
    case 'REMOVE_ITEM':
      return { ...state, items: removeItem(state, action) };
    case 'UPDATE_ITEM_FIELD':
      return { ...state, items: updateItemField(state, action) };
    case 'ADD_CUSTOM_ITEM':
      return { ...state, customItems: addCustomItemToCart(state, action) };
    case 'UPDATE_CUSTOM_ITEM_FIELD':
      return { ...state, customItems: updateCustomItemField(state, action) };
    case 'REMOVE_CUSTOM_ITEM':
      return { ...state, customItems: removeCustomItem(state, action) };
    case 'ADD_TAG':
      return { ...state, tags: addTag(state, action) };
    case 'REMOVE_TAG':
      return { ...state, tags: removeTag(state, action) };
    case 'ADD_NOTE':
      return { ...state, notes: addNote(state, action) };
    case 'REMOVE_NOTE':
      return { ...state, notes: removeNote(state, action) };
    case 'SET_DISCOUNT_PERCENT_VALUE':
      return { ...state, discountPercent: action.payload };
    case 'SET_COUPON':
      return { ...state, coupon: action.payload };
    case 'SET_TOTAL_DAMAGE_DEPOSIT':
      return { ...state, totalDamageDeposit: action.payload };
    case 'CHANGE_ACTUALIZED':
      return { ...state, actualized: action.payload };

    case 'CLEAR_CART':
      return { ...state, items: [], isValid: null, selectedServices: [], isOpen: false };
    case 'APPLY_COUPON':
      return { ...state, coupon: action.payload };
    case 'REMOVE_COUPON':
      return { ...state, coupon: null };
    case 'UPDATE_AVAILABLE_PRODUCTS_COUNT_CART':
      return { ...state, items: updateCartItems(state, action) };
    case 'SET_SERVICES_LIST':
      return { ...state, servicesList: action.payload };
    case 'CHANGE_SELECTED_SERVICE':
      return { ...state, selectedServices: changeSelectedServices(state, action) };
    case 'SET_CART_VALIDATION_RESULT':
      return { ...state, isValid: action.payload.isValid, errorMessage: action.payload.errorMessage };
    case 'SET_ORDER_INFO':
      return { ...state, successOrderInfo: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
