import React, {useEffect} from 'react';

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
  role: string;
  username: string;
  name: string;
  uid: number;
};

export const AuthContext = React.createContext({} as AuthProps);

/**
 * Получение роли пользователя из JWT-токена
 */
const getClaimFromToken = (claim) => {
    const token = localStorage.getItem('auth_token');

    if (token === null) {
        return null;
    }

    const jwtData = token.split('.')[1]
    const decodedJwtJsonData = window.atob(jwtData)
    if (!decodedJwtJsonData) {
        return null;
    }

    const decodedJwtData = JSON.parse(decodedJwtJsonData)

    if (typeof decodedJwtData.role === 'undefined') {
        return null;
    }

    return decodedJwtData?.[claim]?.length !== 0 ? decodedJwtData[claim] : null;
}

const isValidToken = async () => {
    const token = localStorage.getItem('auth_token');
    const tokenExpiry = parseInt(localStorage.getItem('auth_token_expiry')) * 1000;
    const currentDate = new Date();
    const tokenExpiredAt = new Date(tokenExpiry);

    if (token === null) {
        return false;
    }

    // Если токен найден и срок действия не истек
    if (currentDate.getTime() < tokenExpiredAt.getTime()) {
        return true;
    }
    // Иначе делается попытка обновления токена
    const response = await fetch(process.env.REACT_APP_API_URL + '/refresh-token', {
        method: 'POST',
        credentials: 'include'
    })
        .then(response => {
            return response.json();
        });

    // Если обновить не удалось
    if (!response.success) {
        return false;
    }

    localStorage.setItem('auth_token', response.token);
    localStorage.setItem('auth_token_expiry', response.token_expiry);

    return true;
};

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(true);
  const [role, setRole] = React.useState(null);
  const [username, setUsername] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [uid, setUid] = React.useState(null);

  useEffect(() => {
      isValidToken().then(result => {
          makeAuthenticated(result);
          setRole(getClaimFromToken('role'));
          setUsername(getClaimFromToken('username'));
          setName(getClaimFromToken('name'));
          setUid(getClaimFromToken('uid'));
      });
  }, []);

  /**
   * Авторизация пользователя
   *
   * @param email
   * @param password
   * @param cb
   */
  async function authenticate({username, password}, cb) {
    const response = await fetch(process.env.REACT_APP_API_URL + '/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          username: username,
          password: password
        })
    })
        .then(response => {
            return response.json();
        });

    if (!response.success) {
        throw new Error(response.message);
    }

    makeAuthenticated(true);
    localStorage.setItem('auth_token', response.token);
    localStorage.setItem('auth_token_expiry', response.token_expiry);
    setRole(getClaimFromToken('role'));
    setUsername(getClaimFromToken('username'));
    setName(getClaimFromToken('name'));
    setUid(getClaimFromToken('uid'));
  }

  /**
   * Выход из учетной записи
   * @param cb
   */
  async function signout(cb) {
    makeAuthenticated(false);
    setRole(null);
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_token_expiry');
    setTimeout(cb, 100);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
        role,
        username,
        name,
        uid
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
