// Подсчет интервала времени по умолчанию
import moment from "moment";
import {addDays} from "date-fns";

export const calculateDefaultDateRange = () => {
    const roundedUp = Math.ceil(moment().minute() / 15) * 15;

    return [
        moment().minute(roundedUp).second(0).toDate(),
        moment(addDays(new Date(), 1)).minute(roundedUp).second(0).toDate()
    ];
}
